import { createContext, useContext } from 'react'
import { pseudoTemplates } from '@core/api/pseudoTemplates'
import useRecordUserAction from './useRecordUserAction'
import type ApiClient from '@core/api/client'

const ApiContext = createContext<{
  api: ApiClient | null
  session: {
    market: string | null
    uuid: string | null
    data: string | null
  }
}>({ api: null, session: { market: null, uuid: null, data: null } })

export const ApiProvider = ApiContext.Provider

export const useApi = () => {
  const context = useContext(ApiContext)
  if (!context) {
    throw new Error('useApi must be used within an ApiProvider')
  }

  if (!context.api) {
    throw new Error('ApiProvider is not set')
  }

  const api = context.api
  const session = context.session
  const recordUserAction = useRecordUserAction(api, session)

  const getPseudoTemplatesForVariant = (variantId: number) => {
    return pseudoTemplates[variantId]
  }

  return {
    api,
    recordUserAction,
    session,
    pseudoTemplates,
    getPseudoTemplatesForVariant,
  }
}
