import ApiClient from '@core/api/client'
import ErrorPage from '@core/components/ErrorPage'
import LoadingScreen from '@core/components/LoadingScreen'
import { lazy, Suspense } from 'react'
import { createBrowserRouter, Navigate } from 'react-router-dom'
import {
  RedirectAdvocateRedemption,
  RedirectAdvocateRedemptionPast,
  RedirectR,
  RedirectR0,
  RedirectRefer,
} from './redirects'

const PhotoProductsApp = lazy(() => import('@features/photo-products'))
const LoopApp = lazy(() => import('@features/loop'))
const SurveysApp = lazy(() => import('@features/surveys'))
const FmnApp = lazy(() => import('@features/fmn'))
const ReferralApp = lazy(() => import('@features/referral'))

const api = new ApiClient({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  useFaPhotoProxy: import.meta.env.VITE_USE_FA_PHOTO_PROXY === 'true',
})

export const createAppRouter = ({
  supportEmail,
  isPrintifyPreviewLogsActive,
  familyAlbumDownloadDeepLink,
  familyAlbumStoreDeepLink,
  familyAlbumAutoLoginUrl,
}: {
  supportEmail: string
  isPrintifyPreviewLogsActive: boolean
  familyAlbumDownloadDeepLink: string
  familyAlbumStoreDeepLink: string
  familyAlbumAutoLoginUrl: string
}) => {
  return createBrowserRouter([
    // Paths for lazy loaded feature entry points
    {
      path: '/photo-products/*',
      element: (
        <Suspense fallback={<LoadingScreen />}>
          <PhotoProductsApp
            supportEmail={supportEmail}
            isPrintifyPreviewLogsActive={isPrintifyPreviewLogsActive}
          />
        </Suspense>
      ),
    },
    {
      path: '/survey/*',
      element: (
        <Suspense fallback={<LoadingScreen />}>
          <SurveysApp />
        </Suspense>
      ),
    },
    {
      path: '/loop/*',
      element: (
        <Suspense fallback={<LoadingScreen />}>
          <LoopApp supportEmail={supportEmail} familyAlbumAutoLoginUrl={familyAlbumAutoLoginUrl} />
        </Suspense>
      ),
    },
    {
      path: 'referral/*',
      element: (
        <Suspense fallback={<LoadingScreen />}>
          <ReferralApp
            supportEmail={supportEmail}
            familyAlbumDownloadDeepLink={familyAlbumDownloadDeepLink}
            familyAlbumStoreDeepLink={familyAlbumStoreDeepLink}
            familyAlbumAutoLoginUrl={familyAlbumAutoLoginUrl}
            api={api}
          />
        </Suspense>
      ),
    },
    {
      path: '/fmn-offer-wall',
      element: (
        <Suspense fallback={<LoadingScreen />}>
          <FmnApp supportEmail={supportEmail} />
        </Suspense>
      ),
    },
    // Redirections for old routes
    {
      path: '/familyalbum-gps/*',
      element: <Navigate to="/survey/familyalbum-gps/" replace />,
    },
    {
      path: '/user-needs-survey/*',
      element: <Navigate to="/survey/user-needs/" replace />,
    },
    {
      path: '/family-priorities-survey/*',
      element: <Navigate to="/survey/family-priorities/" replace />,
    },
    {
      path: '/modal-loop-holidays',
      element: <Navigate to="/loop/modal/holidays" replace />,
    },
    {
      path: '/modal-loop-mothers-day',
      element: <Navigate to="/loop/mothers-day" replace />,
    },
    {
      path: '/modal-loop',
      element: <Navigate to="/loop/modal" replace />,
    },
    {
      path: '/modal-loop-new-user',
      element: <Navigate to="/loop/modal/new-user" replace />,
    },
    {
      path: '/loop-store/*',
      element: <Navigate to="/loop/store/" replace />,
    },
    {
      path: '/modal-loop-fathers-day',
      element: <Navigate to="/loop/fathers-day" replace />,
    },
    {
      path: '/modal-loop-grandparents-day',
      element: <Navigate to="/loop/grandparents-day" replace />,
    },
    {
      path: '/photo-gifts',
      element: <Navigate to="/photo-products" replace />,
    },
    {
      path: '/refer/:advocateReferralCode',
      element: <RedirectRefer />,
    },
    {
      path: '/advocate/:advocateReferralCode/redemptions/:redemptionId',
      element: <RedirectAdvocateRedemption />,
    },
    {
      path: '/advocate/:advocateReferralCode/redemptions/:redemptionId/past',
      element: <RedirectAdvocateRedemptionPast />,
    },
    {
      path: '/redeem',
      element: <Navigate to="/referral/redeem" replace />,
    },
    {
      path: '/r/:advocateReferralCode',
      element: <RedirectR />,
    },
    {
      path: '/r0/:advocateReferralCode',
      element: <RedirectR0 />,
    },
    {
      path: '/photo-products-tou',
      element: <Navigate to="/photo-products/tou" replace />,
    },
    {
      path: '/pod-modal-milestone-1-month',
      element: <Navigate to="/photo-products?headerType=milestone-1-month" replace />,
    },
    {
      path: '/pod-modal-milestone-3-month',
      element: <Navigate to="/photo-products?headerType=milestone-3-month" replace />,
    },
    {
      path: '/pod-modal-milestone-6-month',
      element: <Navigate to="/photo-products?headerType=milestone-6-month" replace />,
    },
    {
      path: '/pod-modal-milestone-9-month',
      element: <Navigate to="/photo-products?headerType=milestone-9-month" replace />,
    },
    {
      path: '*',
      element: <ErrorPage error={404} message="Page Not Found" />,
    },
  ])
}
